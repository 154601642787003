/* body{
  background-color:#eee;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
 }

 .centered {
  text-align: center;
 } */
 @font-face {
  font-family: 'Franklin Gothic';
  src: url('../public/fonts/FranklinGothic-Heavy.woff2') format('woff2'), url('../public/fonts/EuclidCircularB-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('../public/fonts/FranklinGothic.woff2') format('woff2'),
      url('../public/fonts/FranklinGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('../public/fonts/FranklinGothic-Medium.woff2') format('woff2'),
      url('../public/fonts/FranklinGothic-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html, body {
margin: 0px;
padding: 0px;
width: 100%;
min-height: 100%;
}

body {
font-family: 'Franklin Gothic';
color: #161824;
  font-weight: normal;
  font-style: normal;
font-size: 13px;
background: #FFF ;
overflow-x: hidden;
}

a, a:link, a:visited {
  outline: none;
text-decoration: none;	
-moz-tap-highlight-color: transparent; 
-webkit-tap-highlight-color: transparent; 
}

img {
border: none;
margin: 0px;
max-width: 100%;
}


h1 {
font-family: 'Franklin Gothic';
  font-weight: normal;
font-size: 28px;
margin: 0px;
padding: 0px;	
}

h2 {
font-family: 'Franklin Gothic';
font-weight: normal;
font-size: 24px;
margin: 0px;
padding: 0px;
}

h3, h4, h5, h6 {
font-family: 'Franklin Gothic';
  font-weight: normal;
font-size: 18px;
margin: 0px;
padding: 0px;	
}

form, input, select, textarea, p {
margin: 0px;
padding: 0;
}

textarea {
resize: none;
}

input:focus, textarea:focus {
outline: none;
}

input::-moz-focus-inner { 
  border: 0;
  padding: 0;
}

article, aside, details, figcaption, figure,  
footer, header, hgroup, menu, nav, section, time {  
display: block; 
margin: 0px;
padding: 0; 
} 

.customClear {
clear: both;
font-size: 0em;
line-height: 0px;
height: 0px;
margin: 0px;
padding: 0px;
}


*{
box-sizing: border-box;
}

.main-bg{
background-image: url(../public/images/bg.jpg);
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
min-height: 100vh;
width: 100%;
position: relative;
}

.top-header{
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 20px 0 0;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}

.top-header h1{
  font-size: 50px;
}

.top-header h2{
  font-size: 30px;
}

.ai-page-center{
  padding: 0px 20px;
  position: relative;
  min-height: 100vh;
  height: 100%;
  max-width: 1900px;
  margin: 0 auto;
  }
  .chat-message-item{
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .chat-message-item .user-icon img{
    width: 35px;
    height: 35px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50%;
    border-radius: 10px;
    }
    
    .chat-message-item .user-icon{
    flex-shrink: 0;
    }
    
    .chat-message-wrapper{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 5px;
    }

  .chat-message-item .user-name{
  font-weight: 600;
  }
  
  .chat-message{
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 22px;
  }

  .chat-message-item:not(:last-child){
    padding-bottom: 15px;
  }

  @media screen and  (max-width:1900px) {
    .ai-page-center{
      max-width: 100%;
    }
  }
    